<style>
.add-owner {
    float: right;
}

.ranges {
    max-height: 200px;
    overflow: scroll;
}
</style>
<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-season-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="lg" @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="box.id === null">Register Box</h5>
                <h5 v-if="box.id !== null">Edit Box</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>


            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">

                <b-row>
                    <b-col cols="12">
                        <!-- <b-alert show variant="danger" v-if="errosOcurs" class="p-1">{{ errosOcurs.message }}</b-alert> -->

                        <b-alert show variant="danger" v-if="errosOcurs">
                            <h4 class="alert-heading">
                                {{ errosOcurs.message }}
                            </h4>
                            <div class="alert-body">
                                <div v-for="(errosOcurs, field) in errosOcurs.errors" :key="field">
                                    <strong>{{ field }}:</strong>
                                    <ul>
                                        <li v-for="message in errosOcurs" :key="message">{{ message }}</li>
                                    </ul>
                                </div>
                            </div>
                        </b-alert>


                    </b-col>
                </b-row>
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">

                        <b-row>

                            <b-col cols="6">
                                <b-form-group labe-for="box_number" label="Box number">
                                    <validation-provider #default="{ errors }" name="Box number" rules="">
                                        <b-form-input id="number" name="number" v-model="box.number"
                                            :state="errors.length > 0 ? false : null" size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.number">{{
                                            serverErrors.number[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">

                                <b-form-group labe-for="Box type" label="Box type">
                                    <validation-provider #default="{ errors }" name="Box type" rules="">
                                        <b-form-select v-model="box.type" :options="options"></b-form-select>
                                    </validation-provider>
                                </b-form-group>

                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col>
                                <b-button size="sm" class="add-owner mb-1" variant="outline-primary"
                                    @click="addElement()">Add Range</b-button>

                            </b-col>
                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th>Start</th>
                                        <th>End</th>
                                        <!-- <th width="10%">Quantity</th> -->
                                        <th width="3%"> </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr v-for="element, index in  box.ranges" :key="index">
                                        <td scope="row">
                                            <div>

                                                <b-form-input id="share" name="start" v-model="element.start" size="md" />

                                            </div>
                                        </td>

                                        <td>
                                            <div>

                                                <b-form-input id="share" name="end" v-model="element.end" size="md" />

                                            </div>

                                        </td>
                                        <!-- <td>
                                            <div>

                                                <b-form-input id="share" name="quantity" v-model="element.quantity"
                                                    size="md" />

                                            </div>

                                        </td> -->
                                        <td>
                                            <b-button variant="outline-danger" size="sm" @click="removeRow(index)">
                                                <feather-icon icon="TrashIcon" size="11"
                                                    class="text-danger stroke-current" />
                                            </b-button>
                                        </td>
                                        <!-- {{ element }} -->
                                    </tr>
                                    <tr>
                                        <td scope="row"></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-row>

                        <!-- <pre>
                            {{ box }}
                        </pre> -->
                    </b-form>
                </validation-observer>
            </template>




        </b-modal>
        <!-- ./Form Modal -->

        <!-- Form Range Modal -->
        <b-modal id="modal-range-form" ref="myRangeModal" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submitUpdateRange">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5>Edit Range</h5>

                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="md" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="md" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">

                <b-row>
                    <b-col cols="12">
                        <!-- <b-alert show variant="danger" v-if="errosOcurs" class="p-1">{{ errosOcurs.message }}</b-alert> -->

                        <b-alert show variant="danger" v-if="errosOcurs">
                            <h4 class="alert-heading">
                                {{ errosOcurs.message }}
                            </h4>
                            <div class="alert-body">
                                <div v-for="(errosOcurs, field) in errosOcurs.errors" :key="field">
                                    <strong>{{ field }}:</strong>
                                    <ul>
                                        <li v-for="message in errosOcurs" :key="message">{{ message }}</li>
                                    </ul>
                                </div>
                            </div>
                        </b-alert>


                    </b-col>
                </b-row>
                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="handleCreate">

                        <b-row>

                            <b-col cols="12">
                                <b-form-group labe-for="box_number" label="Box number">
                                    <validation-provider #default="{ errors }" name="Box number" rules="">
                                        <b-form-input id="number" name="number" readonly v-model="range.number"
                                            :state="errors.length > 0 ? false : null" size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.number">{{
                                            serverErrors.number[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>


                        <b-row>
                            <table class="table">
                                <thead>
                                    <tr class="text-center">
                                        <th>Start</th>
                                        <th>End</th>
                                        <!-- <th width="10%">Quantity</th> -->
                                        <th width="3%"> </th>
                                    </tr>
                                </thead>
                                <tbody>

                                    <tr>
                                        <td scope="row">
                                            <div>

                                                <b-form-input id="share" name="start" v-model="range.start" size="md" />

                                            </div>
                                        </td>

                                        <td>
                                            <div>

                                                <b-form-input id="share" name="end" v-model="range.end" size="md" />

                                            </div>

                                        </td>
                                        <!-- <td>
                                            <div>

                                                <b-form-input id="quantity" type="number" name="quantity"
                                                    v-model="range.quantity" size="md" />

                                            </div>

                                        </td> -->
                                        <!-- {{ element }} -->
                                    </tr>
                                    <tr>
                                        <td scope="row"></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </b-row>

                        <b-row>
                            <b-col cols="12">
                                <b-form-checkbox :checked="range.hasMissing" class="custom-control-success"
                                    name="check-button" switch v-model="range.hasMissing">
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckCircleIcon" />
                                    </span>
                                    <span>Have Missing?</span>
                                </b-form-checkbox>
                            </b-col>
                        </b-row>



                        <b-row v-if="range.hasMissing">
                            <b-col cols="5">
                                <b-form-group labe-for="lastBarcode" label="From Barcode" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="lastBarCode" rules="">
                                        <b-form-input id="lastBarcode" name="lastBarcode" v-model="range.lastBarcode"
                                            size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.lastBarcode">{{
                                            serverErrors.lastBarcode[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="5">
                                <b-form-group labe-for="direction" label="Direction" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="Gender" rules="">
                                        <b-form-select size="md" v-model="range.direction" id="direction" name="direction"
                                            :state="errors.length > 0 ? false : null">
                                            <b-form-select-option :value="null">-- Select
                                                --</b-form-select-option>
                                            <b-form-select-option value="1">After</b-form-select-option>
                                            <b-form-select-option value="-1">Before</b-form-select-option>
                                        </b-form-select>
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.direction">{{
                                            serverErrors.direction[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col cols="2">
                                <b-form-group labe-for="counts" label="Counts" style="cursor: pointer">
                                    <validation-provider #default="{ errors }" name="count" rules="">
                                        <b-form-input id="count" type="number" name="count" v-model="range.count"
                                            size="md" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.count">{{
                                            serverErrors.count[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        </b-row>
                        <!-- 
                        <pre>
                            {{ range }}
                        </pre> -->
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Box based on Location</h5>
            </b-card-header>
            <b-card-body>
                <b-row>
                    <b-col cols="6" md="3" class="mb-md-0 mb-2" v-for="(toSelect, index) in selections"
                        :key="toSelect.level">
                        <label>{{ toSelect.label }}</label>
                        <b-form-select size="md" v-model="toSelect.model" @change="changeFilters(toSelect.model, index)">
                            <b-form-select-option :value="null">Select...</b-form-select-option>
                            <b-form-select-option v-for="location in toSelect.locations" :key="location.id"
                                :value="location">
                                {{ location.name.toUpperCase() }}
                            </b-form-select-option>
                        </b-form-select>
                    </b-col>

                    <b-col cols="6">
                        <b-media no-body>
                            <b-media-aside class="mr-2">
                                <b-avatar size="48">
                                    <feather-icon size="24" icon="box" />
                                </b-avatar>
                            </b-media-aside>
                            <b-media-body class="my-auto text-center">
                                <b-row>
                                    <b-col cols="4">
                                        <h5>Total Box</h5>
                                        {{ boxes.length }}
                                    </b-col>
                                    <b-col cols="4">
                                        <h5>Total Bale Tickets</h5>
                                        {{ baleTickets.length }}
                                    </b-col>
                                    <b-col cols="4">
                                        <h5>Total Luggage Tickets</h5>
                                        {{ baleTags.length }}
                                    </b-col>
                                </b-row>

                            </b-media-body>
                        </b-media>
                    </b-col>
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions" :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm" />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input v-model="searchQuery" class="d-inline-block mr-1" placeholder="Search..."
                                size="md" />
                            <b-button variant="primary" size="md" @click="invokeCreateForm()">
                                <span class="text-nowrap">Add Box</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table striped hover small ref="records" class="position-relative" :items="fetch" responsive :fields="columns"
                primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No records found"
                :sort-desc.sync="isSortDirDesc">
                <template #cell(address)="data">
                    {{ data.item.address ? data.item.address.location.name.toUpperCase() : '' }}
                </template>

                <template #cell(type)="data">
                    <b-badge pill :variant="`light-${resolveStatusVariant(data.item.type)}`" class="text-capitalize">
                        <span v-if="data.item.type == 1">Bale Ticket</span>
                        <span v-else-if="data.item.type == 2">Luggage</span>
                    </b-badge>
                </template>
                <template #cell(received)="data">
                    <b-badge pill :variant="`light-${resolveReceivedVariant(data.item.received)}`" class="text-capitalize">
                        <span v-if="data.item.received == true">Yes</span>
                        <span v-else-if="data.item.received == false">No</span>
                    </b-badge>
                </template>

                <template #cell(ranges)="data">
                    <div class="ranges">
                        <table width="100%" class="text-no-wrap">
                            <thead>
                                <tr>
                                    <th class="text-uppercase">
                                        Start
                                    </th>
                                    <th class="text-uppercase">
                                        End
                                    </th>
                                    <!-- <th class="text-uppercase">
                                        Qty
                                    </th> -->

                                    <th class="text-uppercase">
                                        Actual Qty
                                    </th>
                                    <th class="text-uppercase">
                                        Missing
                                    </th>
                                    <th class="text-uppercase">

                                    </th>

                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="range in data.item.ranges">
                                    <td>
                                        {{ range.start }}
                                    </td>
                                    <td>
                                        {{ range.end }}
                                    </td>
                                    <!-- <td>
                                        {{ range.quantity }}
                                    </td> -->
                                    <td>
                                        {{ range.actual }}
                                    </td>
                                    <td>
                                        {{ range.missing }}
                                    </td>
                                    <td>
                                        <b-button variant="outline-primary" size="sm"
                                            @click="invokeRangeUpdateForm(range, data.item)">
                                            <feather-icon icon="EditIcon" />
                                        </b-button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>

                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BBadge, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import boxesStoreModule from '@/views/cromis/inventory/box/boxesStoreModule'
import useBoxesList from '@/views/cromis/inventory/box/useBoxesList'


export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert,
    },
    directives: {},
    watch: {
        userInput(newInput, oldInput) {
            // Update default value when user input changes
            if (!isNaN(newInput)) {
                this.defaultValue -= (newInput - oldInput);
            } else {
                // Handle case where userInput is NaN (e.g., after deleting the number)
                this.defaultValue = this.defaultValue;
            }
        },
    },
    setup(props, context) {
        const dataForm = ref(null)
        const myModal = ref(null)
        const myRangeModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const errosOcurs = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const companies = ref([])
        const societies = ref([])
        const children = ref([])
        const boxes = ref([])
        const baleTickets = ref([])
        const baleTags = ref([])
        const box = ref({
            id: null,
            number: null,
            type: null,
            ranges: [
                {
                    start: null,
                    end: null,
                    quantity: null,
                }
            ]
        })

        const range = ref({
            id: null,
            box_id: null,
            number: null,
            start: null,
            end: null,
            quantity: null,
            hasMissing: false,
            lastBarcode: null,
            direction: null,
            count: null
        })



        const addElement = () => {
            box.value.ranges.push({ start: '', end: '', quantity: '' })

            if (box.value.ranges.length > 0) {


                console.log(box.value.ranges)
            }
        }

        const removeRow = (index) => {
            console.log(index)
            if (box.value.ranges.length > 1) {
                box.value.ranges.splice(index, 1)
                box.value.ranges = box.value.ranges
            }
        }





        const CROMIS_STORE_MODULE_NAME = 'cromis-box'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, boxesStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-box/levels')
                .then(response => {
                    levels.value = response.data.levels[0]

                    selections.value.push({
                        level: levels.value.name.toLowerCase(),
                        model: null,
                        label: levels.value.name,
                        child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                        locations: [],
                    })

                    formSelections.value.push({
                        level: levels.value.name.toLowerCase(),
                        model: null,
                        label: levels.value.name,
                        child: levels.value.children.length > 0 ? levels.value.children[0].name.toLowerCase() : null,
                        locations: [],
                    })

                    let list = levels.value.children

                    while (list.length > 0) {
                        children.value.push(list[0])
                        if (list[0].children > 0) {
                            selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                            formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: list[0].children[0].name.toLowerCase(), locations: [] })
                        }
                        else {
                            selections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                            formSelections.value.push({ level: list[0].name.toLowerCase(), model: null, label: list[0].name, child: null, locations: [] })
                        }

                        list = list[0].children
                    }
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-box/locations')
                .then(response => {
                    populateSelections(response.data.locations)
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-box/companies')
                .then(response => {
                    companies.value = response.data.companies
                })
                .catch(error => {
                    console.log('got error', error)
                })


            await store.dispatch('cromis-box/list')
                .then(response => {
                    boxes.value = response.data.boxes

                    boxes.value.forEach(ele => {
                        if (ele.type == 1) {
                            baleTickets.value.push(ele)
                        } else {
                            baleTags.value.push(ele)
                        }
                    });
                })
                .catch(error => {
                    console.log('got error', error)
                })


            await store.dispatch('cromis-box/societies')
                .then(response => {
                    societies.value = response.data.societies
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }

        const changeFilters = async (location, index) => {
            if (!location) {
                for (let i = index + 1; i < selections.value.length; i++) {
                    selections.value[i].locations.splice(0)
                    selections.value[i].model = null
                }

                if (index > 0) {
                    locationFilter.value = selections.value[index - 1].model.id
                }
                else {
                    locationFilter.value = null
                }
            }
            else {
                if (selections.value[index + 1]) {
                    selections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    selections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    selections.value[index + 1].model = null
                }

                locationFilter.value = selections.value[index].model.id
                if (index === selections.value.length - 1) {
                    box.value.location_id = locationFilter.value
                }
            }
        }

        const changeFormSelection = async (location, index) => {
            if (!location) {
                for (let i = index + 1; i < formSelections.value.length; i++) {
                    formSelections.value[i].locations.splice(0)
                    formSelections.value[i].model = null
                }

                box.value.location_id = null
            }
            else {
                box.value.location_id = null

                if (formSelections.value[index + 1]) {
                    formSelections.value[index + 1].locations.splice(0)
                }

                location.children.map((child) => {
                    formSelections.value[index + 1].locations.push(child)
                })

                if (location.children.length > 0) {
                    formSelections.value[index + 1].model = null
                }

                if (index === formSelections.value.length - 1) {
                    box.value.location_id = location.id
                }
            }
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            resolveStatusVariant,
            resolveReceivedVariant,
            locationFilter,
        } = useBoxesList()

        // Form Methods
        const invokeUpdateForm = (item) => {
            serverErrors.value = null
            errosOcurs.value = null

            box.value = {
                id: item.id,
                type: item.type,
                number: item.number,
                ranges: item.ranges,
                quantity: 1,
            }

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            formSelections.value.map((form) => {
                form.model = null
            })

            box.value = {
                id: null,
                number: null,
                type: null,
                ranges: [
                    {
                        start: null,
                        end: null,
                        quantity: 1,
                    }
                ]
            }

            myModal.value.show()
        }


        const invokeRangeUpdateForm = (ranges, par) => {
            formSelections.value.map((form) => {
                form.model = null
            })


            range.value = {
                id: ranges.id,
                box_id: par.id,
                number: par.number,
                start: ranges.start,
                end: ranges.end,
                quantity: ranges.quantity,
                hasMissing: null,
                lastBarcode: null,
                direction: null,
                count: null
            }

            myRangeModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit
            if (box.value.id === null || box.value.id === 0)
                handleCreate()
            else
                handleUpdate(box.value)
        }

        const submitUpdateRange = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handleUpdateRange(range.value)
        }




        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }


            await store.dispatch('cromis-box/create', box.value)
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Box has been added successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    console.log(error.response.data)
                    errosOcurs.value = error.response.data
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }



        const handleUpdateRange = async (item) => {
            console.log(item)
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-box/range', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myRangeModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to box range has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    console.log(error.response)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors

                        console.log(serverErrors)
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const handleUpdate = async (item) => {
            console.log(item)
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-box/update', { id: item.id, data: item })
                .then(response => {
                    refetch()
                    saving.value = false

                    myModal.value.hide()

                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to box center ${response.data.code} ${response.data.name} has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const confirmDelete = async (id) => {
            const result = context.root.$swal({
                icon: 'info',
                title: 'System Info',
                text: 'Are you sure you want to delete the Box?',
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel',
                customClass: {
                    confirmButton: 'btn btn-info',
                    cancelButton: 'btn btn-danger ml-2', // Optional: Customize the cancel button class
                },
                buttonsStyling: true,
            }).then((result) => {

                if (result.value) {
                    remove(id)
                } else {
                    context.root.$swal({
                        icon: 'info',
                        title: 'Cancelled',
                        text: 'Your Box is safe you cancell deletion of it',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-info',
                        },
                        buttonsStyling: true,
                    })
                }
            })

        }
        const deleteItem = async () => {
            context.root.$swal({
                icon: 'info',
                title: 'Cancelled',
                text: 'Your Box is safe you cancell deletion of it',
                showConfirmButton: true,
                customClass: {
                    confirmButton: 'btn btn-info',
                },
                buttonsStyling: true,
            })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-box/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            box,
            range,
            selectedOwner: null,
            addedElement: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            options: [
                { value: null, text: '-- Select Box Type --' },
                { value: '1', text: 'Bale Ticket' },
                { value: '2', text: 'Luggage' },
            ],
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,
            resolveReceivedVariant,

            // Methods

            required,
            saving,
            serverErrors,
            dataForm,
            myModal,
            myRangeModal,
            changeFilters,
            changeFormSelection,
            locationFilter,
            invokeCreateForm,
            invokeUpdateForm,
            invokeRangeUpdateForm,
            submit,
            submitUpdateRange,
            isFormValid,
            handleCreate,
            handleUpdate,
            handleUpdateRange,
            remove,
            addElement,
            removeRow,
            companies,
            societies,
            confirmDelete,
            deleteItem,
            errosOcurs,
            boxes,
            baleTickets,
            baleTags
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>